export default {
  "header": "_header_h5qprk",
  "logo": "_logo_h5qprk",
  "main": "_main_h5qprk",
  "footer": "_footer_h5qprk",
  "section": "_section_h5qprk",
  "section-link": "_section-link_h5qprk",
  "cta": "_cta_h5qprk",
  "section-one": "_section-one_h5qprk",
  "waves": "_waves_h5qprk",
  "section-two": "_section-two_h5qprk",
  "col": "_col_h5qprk",
  "section-three": "_section-three_h5qprk",
  "section-four": "_section-four_h5qprk",
  "cols": "_cols_h5qprk",
  "section-heading": "_section-heading_h5qprk",
  "icon": "_icon_h5qprk",
  "section-five": "_section-five_h5qprk"
};
