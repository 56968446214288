export default {
  "header": "_header_5wcqcf",
  "logo": "_logo_5wcqcf",
  "virtual-scroller": "_virtual-scroller_5wcqcf",
  "main-mobile": "_main-mobile_5wcqcf",
  "section": "_section_5wcqcf",
  "section-one": "_section-one_5wcqcf",
  "cols": "_cols_5wcqcf",
  "col": "_col_5wcqcf",
  "content": "_content_5wcqcf",
  "step": "_step_5wcqcf",
  "step-ripple": "_step-ripple_5wcqcf",
  "text": "_text_5wcqcf",
  "section-two": "_section-two_5wcqcf",
  "main-desktop": "_main-desktop_5wcqcf",
  "ripple-in": "_ripple-in_5wcqcf",
  "ripple": "_ripple_5wcqcf",
  "cta-container": "_cta-container_5wcqcf",
  "cta": "_cta_5wcqcf",
  "screen": "_screen_5wcqcf",
  "active": "_active_5wcqcf",
  "pager": "_pager_5wcqcf",
  "page": "_page_5wcqcf",
  "footer": "_footer_5wcqcf",
  "container": "_container_5wcqcf",
  "ripple-out": "_ripple-out_5wcqcf"
};
