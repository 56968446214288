import EmberRouter from '@ember/routing/router';
import config from './config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

/* eslint-disable max-nested-callbacks */
Router.map(function () {
  this.route('calibration-certificate', function () {
    this.route('show', {
      path: '/:uuid'
    });
    this.route('lab-import-job', {
      path: 'lab-import-job/:jobId'
    });
  });

  this.route('device-crosschecks-report', {path: 'crosschecks-report/:uuid'});
  this.route('weather-report', {path: 'weather-report/:uuid'});

  this.route('login', {path: '/'});
  this.route('oauth-callback');
  this.route('invitations', {path: 'invitations/:id'});
  this.route('report', {path: 'report/:uuid'});
  this.route('report-template-preview', {
    path: '/report-template-preview/:reportTemplateId'
  });
  this.route('mobile-report-configuration');
  this.route('mobile-message', {path: 'mobile-messages/:messageId'});

  this.route('affiliate', function () {
    this.route('join');
  });

  this.route('lobby', function () {
    this.route('profile');
    this.route('company');
  });

  this.route('admin', function () {
    this.route('companies');
    this.route('devices', function () {
      this.route('move', {path: 'move'});
    });
    this.route('users');

    this.route('calibration-certificates', function () {
      this.route('new', {path: 'new'});
      this.route('edit', {path: ':id/edit'});
    });

    this.route('messages', function () {
      this.route('new', {path: 'new'});
      this.route('edit', {path: ':id/edit'});
    });

    this.route('affiliate-program-ads', function () {
      this.route('new', {path: 'new'});
      this.route('edit', {path: ':id/edit'});
    });
  });

  this.route('app', function () {
    this.route('company', function () {
      this.route('edit');
    });

    this.route('settings', function () {
      this.route('dataset-settings');
      this.route('third-party-settings');
    });

    this.route('datasets', function () {
      this.route('show', {path: '/:externalId'});
      this.route('edit', {path: ':externalId/edit'});
    });

    this.route('report-templates', function () {
      this.route('configure', {path: '/:reportTemplateId/configure'});
    });

    this.route('devices', function () {
      this.route('map', {path: 'map'});
      this.route('crosschecks', {path: ':serialNumber/crosschecks'});
    });

    this.route('messages', () => {});

    this.route('user-management', function () {
      this.route('edit', {path: '/:userId'});
    });

    this.route('profile', function () {
      this.route('edit');
    });

    this.route('affiliate-program');
  });

  // Catch-all error page, put your routes above this one
  this.route('not-found', {path: '/*path'});
});
