// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {inject as service} from '@ember/service';
import {action} from '@ember/object';
import {dropTask} from 'ember-concurrency-decorators';
import window from 'ember-window-mock';

// Config
import config from 'airthings/config/environment';

// Types
import Analytics from 'airthings/services/analytics';
import FlashMessages from 'ember-cli-flash/services/flash-messages';
import IntlService from 'ember-intl/services/intl';
import Account from 'airthings/services/airthings/account';
import {
  AccountUserStruct,
  AffialiateProgramSubscriptionStruct
} from 'airthings/types/account';
import ValidationErrors from 'airthings/services/form';
import {AffiliateProgramAdStruct} from 'airthings/types/affiliate-program';

interface Args {
  currentUser: AccountUserStruct;
  ads: AffiliateProgramAdStruct[];
}

export default class PageAppAffiliateProgram extends Component<Args> {
  @service('analytics')
  analytics: Analytics;

  @service('airthings/account')
  account: Account;

  @service('flash-messages')
  flashMessages: FlashMessages;

  @service('intl')
  intl: IntlService;

  @tracked
  subscription: AffialiateProgramSubscriptionStruct;

  @tracked
  showSubscriptionModal = false;

  get dashboardUrl() {
    return config.APP.affiliateProgramDashboardUrl;
  }

  get reportCouponCode() {
    return this.args.currentUser.affiliateProgram.codes?.report;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.subscription = this.account.affiliateProgramSubscriptionStruct();
  }

  @dropTask
  *subscribeToAffiliateProgramTask() {
    return this.account.subscribeToAffiliateProgram(this.subscription);
  }

  @action
  validate() {
    return this.account.validateAffiliateProgramSubscription(this.subscription);
  }

  @action
  initiateAffiliateProgramSubscription() {
    this.showSubscriptionModal = true;
  }

  @action
  handleSubscriptionSuccess() {
    this.flashMessages.success(
      this.intl.t('app-affiliate-program.form.success')
    );

    this.analytics.trackAffiliateProgramSubscriptionSuccess();

    this.resetForm();
  }

  @action
  cancelSubscription() {
    this.resetForm();
  }

  @action
  handleSubscriptionFailure() {
    this.analytics.trackAffiliateProgramSubscriptionFailure();
  }

  @action
  handleSubscriptionValidationFailure(errors: ValidationErrors) {
    this.analytics.trackAffiliateProgramSubscriptionValidationFailure(errors);
  }

  @action
  changeUniqueCouponPrefix(uniqueCouponPrefix: string) {
    this.subscription = {
      ...this.subscription,
      uniqueCouponPrefix
    };
  }

  @action
  resetForm() {
    this.subscription = this.account.affiliateProgramSubscriptionStruct();
    this.showSubscriptionModal = false;
  }

  @action
  previewAdImage(event: {target: {value: any}}) {
    const selectedUrl = event.target.value;
    if (!selectedUrl) return;

    window.open(selectedUrl, 'blank');

    event.target.value = '';
  }
}
