// Vendor
import Component from '@glimmer/component';
import {tracked} from '@glimmer/tracking';
import {action} from '@ember/object';

const FADE_DURATION_MS = 300;

export default class PageAffiliateJoin extends Component {
  @tracked
  steps = [
    {
      indexLabel: 1,
      image: '/assets/images/affiliation-program/laptop-step-1.png',
      text: 'app-affiliate-join.section-one.steps.step-1'
    },
    {
      indexLabel: 2,
      image: '/assets/images/affiliation-program/laptop-step-2.png',
      text: 'app-affiliate-join.section-one.steps.step-2'
    },
    {
      indexLabel: 3,
      image: '/assets/images/affiliation-program/laptop-step-3.png',
      text: 'app-affiliate-join.section-one.steps.step-3'
    },
    {
      indexLabel: 4,
      image: '/assets/images/affiliation-program/laptop-step-4.png',
      text: 'app-affiliate-join.section-one.steps.step-4'
    }
  ];

  @tracked
  currentStepIndex = 0;

  @tracked
  currentStepText = this.steps[this.currentStepIndex].text;

  get currentStep() {
    return this.steps[this.currentStepIndex];
  }

  get showNextButton() {
    return this.currentStepIndex < this.steps.length - 1;
  }

  @action
  goToStep(stepIndex: number) {
    if (stepIndex < this.steps.length && stepIndex >= 0) {
      this.currentStepIndex = stepIndex;
    }
  }

  @tracked
  textElement: HTMLElement | null = null;

  @action
  nextStep() {
    const scrollHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const pageHeight = scrollHeight / this.steps.length;
    window.scrollTo(0, pageHeight * (this.currentStepIndex + 1) + 1);
  }

  @action
  setupTextAnimation(element: HTMLElement) {
    this.textElement = element;
  }

  @action
  triggerTextFade() {
    this.textElement?.classList.add('fade');

    setTimeout(() => {
      this.currentStepText = this.currentStep.text;
      this.textElement?.classList.remove('fade');
    }, FADE_DURATION_MS);
  }

  @tracked
  stepElement: HTMLElement | null = null;

  @action
  setupStepAnimation(element: HTMLElement) {
    this.stepElement = element;
  }

  @action
  triggerStepAnimation() {
    this.stepElement?.classList.add('pulse');

    setTimeout(() => {
      this.stepElement?.classList.remove('pulse');
    }, 1000);
  }

  @tracked
  sectionElement: HTMLElement | null = null;

  @action
  setupScrollListener(element: HTMLElement) {
    this.sectionElement = element;
    window.scrollTo(0, 0);
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (!this.sectionElement) return;

    const scrollProgress =
      window.scrollY /
      (document.documentElement.scrollHeight - window.innerHeight);

    // Calculate which step we should be on
    const newStepIndex = Math.min(
      this.steps.length - 1,
      Math.floor(scrollProgress * this.steps.length)
    );

    if (newStepIndex !== this.currentStepIndex) {
      this.goToStep(newStepIndex);
    }
  };

  willDestroy() {
    super.willDestroy();
    window.removeEventListener('scroll', this.handleScroll);
  }
}
