export default {
  "font-medium": "500",
  "font-bold": "700",
  "subscription-explanation": "_subscription-explanation_sj2u3p",
  "subscription-actions": "_subscription-actions_sj2u3p",
  "errors": "_errors_sj2u3p",
  "form-wrapper": "_form-wrapper_sj2u3p",
  "input-wrapper": "_input-wrapper_sj2u3p",
  "note": "_note_sj2u3p",
  "active-subscription": "_active-subscription_sj2u3p",
  "coupons": "_coupons_sj2u3p",
  "coupon": "_coupon_sj2u3p",
  "coupon-title": "_coupon-title_sj2u3p",
  "coupon-code": "_coupon-code_sj2u3p",
  "dashboard-link": "_dashboard-link_sj2u3p _button_znm4p3 _is-gray_znm4p3",
  "block-one": "_block-one_sj2u3p",
  "block-one-text-container": "_block-one-text-container_sj2u3p",
  "block-title": "_block-title_sj2u3p",
  "is-gray": "_is-gray_sj2u3p",
  "block-one-body": "_block-one-body_sj2u3p",
  "waves": "_waves_sj2u3p",
  "block-two": "_block-two_sj2u3p",
  "block-two-text-container": "_block-two-text-container_sj2u3p",
  "options": "_options_sj2u3p",
  "option": "_option_sj2u3p",
  "is-bold": "_is-bold_sj2u3p",
  "option-title": "_option-title_sj2u3p",
  "option-body": "_option-body_sj2u3p",
  "is-faded": "_is-faded_sj2u3p",
  "option-body-two-column": "_option-body-two-column_sj2u3p",
  "option-or": "_option-or_sj2u3p",
  "option-or-label": "_option-or-label_sj2u3p",
  "block-three": "_block-three_sj2u3p",
  "block-three-text-container": "_block-three-text-container_sj2u3p",
  "block-three-body": "_block-three-body_sj2u3p",
  "ads": "_ads_sj2u3p",
  "ads-body": "_ads-body_sj2u3p",
  "ads-title": "_ads-title_sj2u3p",
  "ads-pro": "_ads-pro_sj2u3p",
  "ads-pro-title": "_ads-pro-title_sj2u3p",
  "ads-pro-body": "_ads-pro-body_sj2u3p",
  "ad-preview": "_ad-preview_sj2u3p"
};
